<template>
  <!-- 页面name：督导模板 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">亮点库</div>
      <div>
      </div>
    </div>
    <!-- 表单 -->
    <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
      <el-form-item>
        <el-select v-model="form.orgId" class="select-input" clearable placeholder="请选择分公司" @change="handleCompanyChange">
          <el-option v-for="item in companyOptions" :key="item.label" :label="item.name" :value="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.parkId" class="select-input" clearable placeholder="请选择园校" :disabled="isHandleSchool">
          <el-option v-for="item in schoolOptions" :key="item.label" :label="item.name" :value="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.supervisionTopic" class="select-input" placeholder="请输入督导主题" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.projectName" class="select-input" placeholder="请输入督导项目" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.userName" class="select-input" placeholder="请输入判定人" />
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="createTime" class="select-time" type="datetimerange" range-separator="至" start-placeholder="加入开始时间" end-placeholder="加入结束时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="handleCreateTime"></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="resetFun">重置</el-button>
      </el-form-item>
    </el-form>
    <l-table :columns="columns" :dataSource="tableData" :pagination="pageFlag" :ipagination="page" @current-change="handleCurrentChange" @size-change="handleSizeChange" :loading="tableLoading" :showXh="true" :height="'calc(100% - 2.91667vw)'" :autoHeight="false">
      <!-- <el-table-column prop="orgName" label="分公司" align="center" width="200" slot="orgName"></el-table-column> -->
      <el-table-column prop="parkName" label="园校" align="center" width="180" slot="parkName"></el-table-column>
      <el-table-column prop="supervisionTopic" label="督导主题" align="center"  width="180" slot="supervisionTopic"></el-table-column>
      <el-table-column prop="projectName" label="督导项目" align="center"  width="180" slot="projectName"></el-table-column>
      <el-table-column prop="score" label="得分" align="center" slot="score" width="80"></el-table-column>
      <el-table-column prop="highlights" label="亮点" align="center" slot="highlights" width="180"></el-table-column>
      <el-table-column prop="joinReason" label="加入原因" align="center" slot="joinReason" width="100"></el-table-column>
      <el-table-column prop="userName" label="判定人" align="center" width="120" slot="userName"></el-table-column>
      <el-table-column prop="createTime" label="加入时间" width="150" align="center" slot="createTime"></el-table-column>
      <el-table-column label="操作" fixed="right" slot="handle" align="center">
        <template slot-scope="scope">
          <el-button class="table-handle" @click="handleLightspotFun('view',scope.row)" v-if="isView" type="text">查看</el-button>
          <el-popconfirm confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-warning" icon-color="#E6BD89" title="你确定要删除此亮点项吗？" v-if="isDelete" @confirm="handleLightspotFun('remove',scope.row)">
            <el-button slot="reference" type="text" class="table-handle" v-if="isDelete">删除</el-button>
          </el-popconfirm>
          <el-button class="table-handle" @click="handleLightspotFun('share',scope.row)" type="text" v-if="isShare">分享</el-button>
        </template>
      </el-table-column>
    </l-table>
    <share-dialog @returnFun="shareReturnFun" :shareDialogVisible="shareDialogVisible" :shareType="shareType"></share-dialog>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import { getPermissionButton, isHasPermission, } from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
import ShareDialog from '@/views/supervise/agencyTask/components/shareDialog.vue';
export default {
  name: 'lightspot',
  components: { LTable, ShareDialog, },
  data() {
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        // {
        //   label: "分公司",
        //   slotName: "orgName",
        // },
        {
          label: "园校",
          slotName: "parkName",
        },
        {
          label: "督导主题",
          slotName: "supervisionTopic",
        },
        {
          label: "督导项目",
          slotName: "projectName",
        },
        {
          label: "得分",
          slotName: "score",
        },
        {
          label: "亮点",
          slotName: "highlights",
        },
        {
          label: "加入原因",
          slotName: "joinReason",
        },
        {
          label: "判定人",
          slotName: "userName",
        },
        {
          label: "加入时间",
          slotName: "createTime",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      form: {
        orgId: '',
        parkId: '',
        supervisionTopic: "",
        projectName: '',
        userName: "",
        beginTime: '',
        endTime: '',
      },
      isHandleSchool: true,
      createTime: [],//发布时间选择的时间段
      companyOptions: [],//分公司下拉
      schoolOptions: [],//园校下拉
      tableData: [],//表单数据

      shareDialogVisible: false,//分享标识
      shareType: '',//分享类型

      permissionButtonList: [], // 权限按钮list
      isView: false,
      isShare: false,
      isDelete: false,
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isView = val.indexOf("z_lightspot:view") != -1;
          this.isShare = val.indexOf("z_lightspot:share") != -1;
          this.isDelete = val.indexOf("z_lightspot:del") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.getRangeList("NODE_ORG")
    this.tableLoading = true
    // this.init();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.init();
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      let param = {}
      for(let key in this.form){
        if(this.form[key]){
          param[key] = this.form[key]
        }
      }
      param.level = '1'
      param.current = this.page.num
      param.size = this.page.size
      this.$api.getLightapotList(param).then((res) => {
        if(res.data.code == 0){
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          if (res.data.data.total >= 1) {
            this.pageFlag = true;
          } else {
            this.pageFlag = false;
          }
        } else {
          this.tableLoading = false;
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    //获取分公司和园校下拉
    getRangeList(type,parent){
      let param = {
        type
      }
      if(type == 'NODE_SCHOOL'){
        param.parent = parent
      }
      this.$api.getCompanyDept(param).then((res) => {
        if(res.data.code == 0){
          if(type == 'NODE_SCHOOL'){
            this.schoolOptions = res.data.data || []
          } else if(type == 'NODE_ORG'){
            this.companyOptions = res.data.data || []
          }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {})
    },
    //处理选择分公司变化
    handleCompanyChange(val){
      console.log(val)
      this.form.parkId = ''
      this.schoolOptions = []
      if(val){
        this.isHandleSchool = false
        this.getRangeList('NODE_SCHOOL',val)
      } else {
        this.isHandleSchool = true
      }
    },
    //时间变化事件
    handleCreateTime(val){
      console.log(val)
      if(val && val.length > 0){
        this.form.beginTime = val[0]
        this.form.endTime = val[1]
      } else {
        this.form.beginTime = ''
        this.form.endTime = ''
      }
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.tableLoading = true
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.orgId = "";
      this.form.parkId = "";
      this.isHandleSchool = true
      this.schoolOptions = []
      this.form.supervisionTopic = "";
      this.form.projectName = "";
      this.form.userName = ''
      this.form.beginTime = "";
      this.form.endTime = "";
      this.createTime = []
      this.page.num = 1;
      this.tableLoading = true
      this.init();
    },
    //分享返回事件
    shareReturnFun(data){
      if(data.flag){
        let personList = data.personList
        console.log(personList)
        personList.map((item) => {
          item.id = this.taskId
        })
        let params = personList
        this.$api.shareLightspot(params).then((res) => {
          if(res.data.code == 0){
            this.$message.success("分享成功");
            this.shareDialogVisible = false
            this.$nextTick(() => {
              this.taskId = null
              this.shareType = ''
              // this.searchFun()
            })
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(() => {})
      } else {
        this.shareDialogVisible = false
        this.$nextTick(() => {
          this.taskId = null
          this.shareType = ''
        })
      }
    },
    /** 行内-编辑 */
    handleLightspotFun(type,row) {
      switch(type){
        case 'remove':
          this.$api.removeLightspot(row.id).then((res) => {
            if(res.data.code == 0){
              this.$message({ type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                this.tableLoading = true
                this.init();
              }, });
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(() => {})
          break;
        case 'view':
          sessionStorage.setItem("parkName",row.parkName)
          this.$router.push({ path: "/lightspotDetail", query: { id: row.id, jumpType: 'headquarters' } });
          break;
        case 'share':
          this.taskId = row.id
          this.shareDialogVisible = true
          this.$nextTick(() => {
            this.shareType = 'headquarters'
          })
          break;
        default:
          break;
      }
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.tableLoading = true
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.tableLoading = true
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .header-title{
      color: #3D3E4F;
      font-size: 22px;
      font-weight: bold;
    }
    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      width: auto;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  /deep/.el-switch__label.is-active{
    color: #010334;
  }
  /deep/.el-switch__label{
    color: #010334;
  }
  /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: #003685 !important;
    border-color: #003685 !important;
  }
  .table-handle{
    margin-right: 10px;
    margin-left: 0;
  }
  .tableList {
    height: calc(100% - 165px);
  }
  .description-div{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .select-input{
    width: 200px;
  }
  .select-time{
    width: 400px;
  }
}
</style>